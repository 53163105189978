interface Values {
  downstreamInvestmentBankability: string;
  identifiedDownstreamFinancingSource: string;
}

export default function isQualified(values: Values) {
  const rightAnswer = "yes";

  return (
    values.downstreamInvestmentBankability === rightAnswer &&
    values.identifiedDownstreamFinancingSource === rightAnswer
  );
}
