import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "landing",
    component: () => import("@/views/Landing/landing-view.vue"),
  },
  {
    path: "/investee/account",
    name: "account",
    component: () => import("vdr-framework/src/layout/Layout.vue"),
    meta: { sidebar: "account" },
    children: [
      {
        path: "",
        name: "account-view",
        component: () => import("@/views/account/account-view.vue"),
        children: [
          {
            path: "",
            name: "account-details",
            component: () =>
              import("@/views/account/approved/account-details.vue"),
          },
          {
            path: "team",
            name: "account-team",
            component: () =>
              import("@/views/account/approved/account-team.vue"),
          },
          {
            path: "notifications",
            name: "account-notifications",
            component: () =>
              import("@/views/account/approved/account-notifications.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/account/password-reset",
    name: "account-password",
    component: () => import("vdr-framework/src/layout/Layout.vue"),
    meta: { sidebar: "account" },
    children: [
      {
        path: "",
        name: "account-password-reset",
        component: () =>
          import("vdr-framework/src/views/account/password-reset.vue"),
      },
    ],
  },
  {
    path: "/investee/projects",
    name: "projects",
    component: () => import("vdr-framework/src/layout/Layout.vue"),
    children: [
      {
        path: ":submissionId",
        name: "project-detail",
        meta: { sidebar: "submission" },
        component: () =>
          import(
            "@/custom-components/investee-projects/investee-project-wrapper.vue"
          ),
        children: [
          {
            path: "",
            name: "application",
            component: () => import("@/views/investee-steps/step1/Step1.vue"),
            redirect: {
              name: "investee-interest",
            },
            children: [
              {
                path: "interest",
                name: "investee-interest",
                component: () =>
                  import(
                    "@/views/investee-steps/step1/views/interest-form.vue"
                  ),
              },
              {
                path: "onboarding",
                name: "investee-onboarding",
                component: () =>
                  import(
                    "@/views/investee-steps/step1/views/onboarding-form.vue"
                  ),
              },
            ],
          },
          {
            path: "review",
            name: "review",
            component: () => import("@/views/investee-steps/step2/Step2.vue"),
          },
          {
            path: "bdr",
            name: "bdr",
            component: () => import("@/views/investee-steps/step3/Step3.vue"),
          },
          {
            path: "investment",
            name: "investment",
            redirect: {
              name: "investee-investment_match",
            },
            component: () => import("@/views/investee-steps/step4/Step4.vue"),
            children: [
              {
                path: "match",
                name: "investee-investment_match",
                component: () =>
                  import("@/custom-components/match-list/inProgress-list.vue"),
              },
              {
                path: "closed",
                name: "investee-investment_closed",
                component: () =>
                  import("@/custom-components/match-list/closed-list.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "",
        name: "projects",
        component: () =>
          import("@/custom-components/investee-projects/investee-projects.vue"),
      },
    ],
  },
  {
    path: "/investee",
    name: "investee",
    redirect: "/investee/projects",
    children: [
      {
        path: "auth",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
          {
            path: "sign-in",
            name: "investee-sign-in",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
          },
          {
            path: "sign-up",
            name: "investee-sign-up",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
          },
          {
            path: "password-reset",
            name: "investee-password-reset",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/PasswordReset.vue"
              ),
          },
          {
            path: "password-recovery",
            name: "investee-password-recovery",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/investor",
    name: "investor",
    redirect: "/investor/home/application",
    meta: { sidebar: "submission" },
    children: [
      {
        path: "home",
        name: "investor-home",
        component: () => import("vdr-framework/src/layout/Layout.vue"),
        children: [
          {
            path: "application",
            name: "investor-application",
            component: () => import("@/views/investor-steps/step1/Step1.vue"),
          },
          {
            path: "review",
            name: "investor-review",
            component: () => import("@/views/investor-steps/step2/Step2.vue"),
          },
          {
            path: "onboarding",
            name: "investor-onboarding",
            component: () => import("@/views/investor-steps/step3/Step3.vue"),
          },
          {
            path: "investment",
            name: "investor-investment",
            redirect: {
              name: "investor-investment_available",
            },
            component: () => import("@/views/investor-steps/step4/Step4.vue"),
            children: [
              {
                path: "available",
                name: "investor-investment_available",
                component: () =>
                  import(
                    "@/views/investor-steps/step4/views/available-list.vue"
                  ),
              },
              {
                path: "inProgress",
                name: "investor-investment_match",
                component: () =>
                  import("@/custom-components/match-list/inProgress-list.vue"),
              },
              {
                path: "closed",
                name: "investor-investment_closed",
                component: () =>
                  import("@/custom-components/match-list/closed-list.vue"),
              },
            ],
          },
          {
            path: "feedback/:id",
            name: "investor-match-feedbaack",
            component: () =>
              import("@/views/investor-steps/step4/views/feed-back.vue"),
          },
          {
            path: "materials/:id/:view",
            name: "investor-match-materials",
            component: () =>
              import("@/views/investor-steps/step4/views/materials-view.vue"),
          },
        ],
      },
      {
        path: "auth",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
          {
            path: "sign-in",
            name: "investor-sign-in",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
          },
          {
            path: "sign-up",
            name: "investor-sign-up",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
          },
          {
            path: "password-reset",
            name: "investor-password-reset",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/PasswordReset.vue"
              ),
          },
          {
            path: "password-recovery",
            name: "investor-password-recovery",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
