
import { defineComponent, onMounted } from "vue";
import FrameworkApp from "vdr-framework/src/App.vue";
import packageJson from "../package.json";

export default defineComponent({
  name: "app",
  components: { FrameworkApp },
  setup() {
    onMounted(() => {
      console.log("Application loaded, version " + packageJson.version);
    });
  },
});
